<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { bedTypeList } from '@/utils/textFile'
export default {
  name: 'live',
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/goods/farmHomeStay/page',
      current: 1
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '名称',
          key: 'name',
          type: 'input'
        },
        {
          name: '店铺名称',
          key: 'mainBodyName',
          type: 'input'
        },
        {
          name: 'ID',
          key: 'id',
          type: 'input'
        },
        {
          name: '现价',
          type: 'row',
          children: [
            {
              type: 'input',
              cols: 11,
              label: '最低现价',
              key: 'minPrice'
            },
            {
              type: 'text',
              value: '至',
              cols: 2,
              align: 'center'
            },
            {
              type: 'input',
              cols: 11,
              label: '最高现价',
              key: 'maxPrice'
            }
          ]
        },
        {
          name: '会员价',
          type: 'row',
          children: [
            {
              type: 'input',
              cols: 11,
              label: '最低会员价',
              key: 'minVipPrice'
            },
            {
              type: 'text',
              value: '至',
              cols: 2,
              align: 'center'
            },
            {
              type: 'input',
              cols: 11,
              label: '最高会员价',
              key: 'maxVipPrice'
            }
          ]
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'name',
          title: '名称',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div class="product-order-item-copy">
                <img src={records.img} />
                <div>{text}</div>
              </div>
            )
          },
          //   onExport: (text, records) => {
          //     return `${records.img} / ${text}`
          //   },
          sorter: (a, b) => a.name.length - b.name.length
        },
        {
          dataIndex: 'mainBodyName',
          title: '店铺名称',
          align: 'left',
          sorter: (a, b) => a.mainBodyName.length - b.mainBodyName.length
        },
        {
          dataIndex: 'bedType',
          title: '床位',
          align: 'left',
          onExport: text => (bedTypeList[text] || { text: '' }).text,
          filters: bedTypeList,
          filterMultiple: false,
          customRender: text => (bedTypeList[text] || { text: '' }).text
        },
        {
          dataIndex: 'breakfastState',
          title: '早餐',
          align: 'center',
          type: 'badge',
          isId: true,
          onExport: records => {
            return ['有', '无'][records]
          },
          filters: [
            {
              text: '有',
              value: '0'
            },
            {
              text: '无',
              value: '1'
            }
          ],
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '有' : '无',
              color: data == 0 ? 'green' : 'red'
            }
          }
        },
        {
          dataIndex: 'salePrice',
          title: '现价',
          align: 'left',
          customRender: function(text, records) {
            return records.salePrice == null ? '' : <div>{'￥' + records.salePrice}</div>
          }
        },
        {
          dataIndex: 'vipPrice',
          title: '会员价',
          align: 'left',
          customRender: function(text, records) {
            return records.vipPrice == null ? '' : <div>{'￥' + records.vipPrice}</div>
          }
        },
        {
          dataIndex: 'stockNum',
          title: '每天可订',
          isId: true,
          align: 'left',
          sorter: (a, b) => a.stockNum - b.stockNum
        },
        {
          dataIndex: 'upDown',
          title: '状态',
          width: '60px',
          type: 'badge',
          align: 'center',
          onExport: records => {
            return ['上架', '下架'][records]
          },
          filters: [
            {
              text: '上架',
              value: '0'
            },
            {
              text: '下架',
              value: '1'
            }
          ],
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '上架' : '下架',
              color: data == 0 ? 'green' : 'red'
            }
          }
        },
        {
          dataIndex: 'a9',
          title: '操作',
          type: 'buttonGroup',
          typeData: ({ records }) => {
            return [
              {
                name: records.upDown == 0 ? '下架' : '上架',
                onClick: () => {
                  api.command.editGET.call(this, {
                    url: `/api/goods/farmHomeStay/${records.upDown == 0 ? 'down' : 'up'}?id=${records.id}`
                  })
                }
              },
              {
                name: '编辑',
                onClick: () => this.$router.push(`/commodityManagement/liveDetail?id=${records.id}`)
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () =>
                  api.command.del.call(this, {
                    url: `/goods/farmHomeStay?id=${records.id}`
                  })
              }
            ]
          }
        }
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push('/commodityManagement/liveDetail')
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.delPut.call(this, {
                  url: '/goods/farmHomeStay/deleteBatch',
                  params: { idList }
                })
              }
            },
            {
              name: '批量上架',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.edit.call(this, {
                  url: '/goods/farmHomeStay/upBatch',
                  params: { idList }
                })
              }
            },
            {
              name: '批量下架',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.edit.call(this, {
                  url: '/goods/farmHomeStay/downBatch',
                  params: { idList }
                })
              }
            }
          ]
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  }
}
</script>
